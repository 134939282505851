<div *ngIf="!hideFooter">
    <div>
        <!-- Footer Start -->
        <footer class="footer bg-dark">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="assets/images/driftdata-logo-inverted.png" height="24" alt="" />
                        </a>
                        <p class="mt-4 text-muted">
                            Start working with DriftData. Improve operations, reduce costs and amaze your customers.
                        </p>
                    </div>
                    <!--end col-->

                    <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Company</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/aboutus" class="text-muted"
                                    ><i class="uil uil-angle-right-b me-1"></i> About us</a
                                >
                            </li>
                            <li>
                                <a routerLink="/contact" class="text-muted"
                                    ><i class="uil uil-angle-right-b me-1"></i> Contact</a
                                >
                            </li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Usefull Links</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/page-terms" class="text-muted"
                                    ><i class="uil uil-angle-right-b me-1"></i> Terms of Services</a
                                >
                            </li>
                            <li>
                                <a routerLink="/page-privacy" class="text-muted"
                                    ><i class="uil uil-angle-right-b me-1"></i> Privacy Policy</a
                                >
                            </li>
                        </ul>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
                <div class="row align-items-center">
                    <div class="col-sm-6">
                        <div class="text-sm-start">
                            <p class="mb-0">
                                ©

                                {{ year }} Drift Data Systems
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
    </div>
</div>
<!--end footer-->

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solution-claimsbee',
    templateUrl: './solution-claimsbee.component.html',
    styleUrls: ['./solution-claimsbee.component.css'],
})

/***
 * Task Management Component
 */
export class SolutionClaimsBeeComponent implements OnInit {
    /***
     * nav bg color add
     */
    navClass = 'nav-light';

    servicesData = [
        {
            icon: 'uil uil-edit-alt h1 text-primary',
            title: 'Smart',
            description:
                'Platform is performing complex tasks in a pleasant, concise, logical and simple way for the user.',
        },
        {
            icon: 'uil uil-airplay h1 text-primary',
            title: 'Secure',
            description:
                'Data is encrypted both at rest and in transit. The platorm is implemented using latest security standards and protocols.',
        },
        {
            icon: 'uil uil-envelope-shield h1 text-primary',
            title: 'Composable',
            description:
                'Everything is declarative, reusable and composable. From field groups, forms to process steps.',
        },
        {
            icon: 'uil uil-edit-alt h1 text-primary',
            title: 'Low code',
            description: 'Everything is configurable: Forms, Workflows, Worklist items, searhable fields, Follow-ups ',
        },
        {
            icon: 'uil uil-edit-alt h1 text-primary',
            title: 'Integrated',
            description:
                'Lots of out of the box integrations. Easy to implement new integration flows using BPlatform own integration DSL',
        },
    ];

    constructor() {}

    ngOnInit(): void {}
}

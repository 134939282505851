import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css'],
})

/**
 * Index Component
 */
export class IndexComponent implements OnInit {
    /**
     * Partners slider
     */
    navClass = 'nav-light';

    constructor() {}

    ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-page-aboutus-two',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.css'],
})

/**
 * Aboutus Two Component
 */
export class AboutusComponent implements OnInit {
    navClass = 'nav-light';
    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {}

    /**
     * Open modal for show the video
     * @param content content of modal
     */
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
    }
}

import { IndexComponent } from './core/components/index/index.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { AboutusComponent } from './core/components/aboutus/aboutus.component';

import { ContactComponent } from './core/components/contact/contact.component';

import { PageThankyouComponent } from './core/components/page-thankyou/page-thankyou.component';

import { ComingsoonComponent } from './core/components/comingsoon/comingsoon.component';

import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { FeatherModule } from 'angular-feather';

import { allIcons } from 'angular-feather/icons';

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PlatformComponent } from './core/components/platform/platform.component';
import { SolutionClaimsBeeComponent } from './core/components/solution-claimsbee/solution-claimsbee.component';

const routes: Routes = [
    {
        path: '',
        component: MasterPageComponent,
        children: [
            { path: '', component: IndexComponent },
            { path: 'index', component: IndexComponent },
            { path: 'aboutus', component: AboutusComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'page-terms', component: PageTermsComponent },
            { path: 'page-privacy', component: PagePrivacyComponent },
            { path: 'claims-bee', component: SolutionClaimsBeeComponent },
            { path: 'platform', component: PlatformComponent },
        ],
    },

    { path: 'comingsoon', component: ComingsoonComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            scrollOffset: [0, 0],
            // Enable scrolling to anchors
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

<!-- Hero Start -->
<section
    class="bg-half-170 pb-0 bg-primary d-table w-100"
    id="home"
    style="background: url('assets/images/bg2.png') center center"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="title-heading">
                    <h4 class="heading text-white mb-3 title-dark">
                        <ngx-typed-js
                            [strings]="['everywhere', 'anything', 'fast']"
                            [shuffle]="true"
                            [typeSpeed]="90"
                            [loop]="true"
                        >
                            Automate <span class="element text-white typing"></span>
                        </ngx-typed-js>
                    </h4>
                    <p class="para-desc text-white-50">
                        Build unique and rich experiences for your <br />
                        employees, customers and business partners
                    </p>
                    <div class="mt-4 pt-2">
                        <a [routerLink]="'/contact'" class="btn btn-light">Get in touch</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-5 mt-sm-0">
                <img src="assets/images/hero1.png" class="img-fluid" alt="" />
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<!--section class="py-4">
    <div class="container py-4">
        <app-clients-logo></app-clients-logo>

    </div>
</section-->
<!--end section-->
<!-- Partners End -->

<section class="section" bg-light>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">What we do ?</h4>
                    <p>
                        We are a software company that offers services (sales, implementation, support) around our 
                        platform BPlatform.
                    </p>
                    <p>
                        <strong>BPlatform</strong> is the foundation of our claims management solution called
                        <strong>ClaimsBee</strong>.
                    </p>
                    <p>
                        For our customers, <strong>ClaimsBee</strong> ensures the complete or partial
                        digitization of the main stages of the claims management process: notification, damage
                        evaluation, repair, damage re-assessment, checks and decisions, compensation request and
                        includes functionalities for interaction with claim parties and claim-related document
                        management, as well as functionalities for automating other activities, such as claim settlement
                        and fraud.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">What is the motivation?</h4>
                    <p>
                        While providing automation and consulting services in the insurance industry and dealing 
                        daily with business experts, we discovered that there is no simple, modern platform capable 
                        of addressing the challenges raised by the complexity of business rules, regulations, and 
                        systems architecture. There are either expensive and complex systems that require extensive resources 
                        to implement and extensive training for the users or simple (rather simplistic) systems that are easy 
                        to implement and use but only automate parts of the processes.
                    </p>
                    <p>
                        With our passion for technology, our expertise in building software for the insurance industry, 
                        and taking advantage of new technology innovations, we began to build a new platform with the goal 
                        of making it very easy to use, customize, and integrate while also being smart enough to automate 
                        end-to-end processes.
                    </p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">&nbsp;</h4>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Key platform features</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="
                        card
                        features
                        fea-primary
                        rounded
                        p-4
                        bg-light
                        text-center
                        position-relative
                        overflow-hidden
                        border-0
                    "
                >
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-users-alt"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>User friendly</h5>
                        <p class="para text-muted mb-0">Concise messages, minimalistic design, animations.</p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-users-alt"></i>
                    </span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="
                        card
                        features
                        fea-primary
                        rounded
                        p-4
                        bg-light
                        text-center
                        position-relative
                        overflow-hidden
                        border-0
                    "
                >
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-crosshairs"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Composable</h5>
                        <p class="para text-muted mb-0">
                            Smart architecture and innovative software components composable and reusable.
                        </p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-crosshairs"></i>
                    </span>
                </div>
            </div>

            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="
                        card
                        features
                        fea-primary
                        rounded
                        p-4
                        bg-light
                        text-center
                        position-relative
                        overflow-hidden
                        border-0
                    "
                >
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-data-sharing"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Integrated</h5>
                        <p class="para text-muted mb-0">
                            Lots of out of the box integrations. <br />
                            Integration DSL for fast integration definition.
                        </p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-data-sharing"></i>
                    </span>
                </div>
            </div>

            <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
                <a [routerLink]="'/platform'" class="btn btn-primary"
                    >See more
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                </a>
            </div>
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

<section class="bg-half-120 bg-primary d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12">
                <div class="title-heading text-center">
                    <h1 class="heading title-dark text-white mb-3">B-Platform overview</h1>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<!-- Features Start -->
<section class="section mt-0">
    <div class="container">
        <app-services [servicesData]="servicesData"></app-services>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="/assets/images/bplatform/my_activities.png" class="img-fluid shadow rounded-md" alt="" />
            </div>

            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-5">
                    <h1 class="text-primary"><i class="uil uil-schedule"></i></h1>
                    <h4 class="title mb-4">Clean, minimalistic design</h4>
                    <p class="text-muted">
                        Simple, responsive, modern design perfectly adapted to desktops, mobile phones, and tablets. For
                        better usability on mobile and tablet devices, the user can navigate within the workflow steps
                        through swipe left / right action.
                    </p>
                    <ul class="list-unstyled text-muted">
                        <li>
                            <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
                            Customer own branding
                        </li>
                        <li>
                            <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
                            Animated and pleasant to use
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Features for operational teams</h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <ul ngbNav #nav="ngbNav" class="nav-pills flex-column rounded col-md-5 pr-0 bg-white">
                <li ngbNavItem class="bg-light rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Individual and team shortlist</h5>
                            <p class="text-muted tab-para mb-0">
                                Some activities can last months to complete because they can be parked and wait for an
                                outside event to be revived. To keep users focused there is a shortlist feature that
                                split tasks into active and pending tasks.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="saas-feature-shape-right position-relative">
                            <img src="/assets/images/bplatform/my_team.png" class="img-fluid mx-auto d-block" alt="" />
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Worklist</h5>
                            <p class="text-muted tab-para mb-0">
                                List with activities divided by status (active, pending, finished, archived).
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <img src="/assets/images/bplatform/worklist.png" class="img-fluid mx-auto d-block" alt="" />
                    </ng-template>
                </li>
                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Multi-Step activities</h5>
                            <p class="text-muted tab-para mb-0">
                                Complex workflows can be split into logical steps that guide the user through task
                                completion.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <img src="/assets/images/bplatform/multistep.png" class="img-fluid mx-auto d-block" alt="" />
                    </ng-template>
                </li>

                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Timeline with business events</h5>
                            <p class="text-muted tab-para mb-0">Complete events history of the task is visible.</p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <img src="/assets/images/bplatform/events_list.png" class="img-fluid mx-auto d-block" alt="" />
                    </ng-template>
                </li>
                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Comments system</h5>
                            <p class="text-muted tab-para mb-0">
                                Smart comments system encourages collaboration between users.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <img src="/assets/images/bplatform/comments.png" class="img-fluid mx-auto d-block" alt="" />
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="col-md-7 col-12 mt-4 pt-2"></div>
        </div>
        <!--end col-->
    </div>

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-12">
                <div class="sticky-bar">
                    <div class="section-title text-lg-left mb-4 mb-lg-0 pb-2 pb-lg-0">
                        <h4 class="title mb-4">Smart automation features</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-8 col-12">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div
                                    class="
                                        card
                                        features
                                        fea-primary
                                        rounded
                                        p-4
                                        bg-light
                                        position-relative
                                        overflow-hidden
                                        border-0
                                    "
                                >
                                    <span class="h1 icon2 text-primary">
                                        <i class="uil uil-meeting-board"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Internal rules engine</h5>
                                        <p class="para text-muted mb-0">
                                            Complex rule sets can be configured as prerequisites for specific activities
                                            or actions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12 mt-4 pt-2">
                                <div
                                    class="
                                        card
                                        features
                                        fea-primary
                                        rounded
                                        p-4
                                        bg-light
                                        position-relative
                                        overflow-hidden
                                        border-0
                                    "
                                >
                                    <span class="h1 icon2 text-primary">
                                        <i class="uil uil-schedule"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Follow-ups</h5>
                                        <p class="para text-muted mb-0">
                                            Platform help you increase process quality through follow-ups.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end col-->

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div
                                    class="
                                        card
                                        features
                                        fea-primary
                                        rounded
                                        p-4
                                        bg-light
                                        position-relative
                                        overflow-hidden
                                        border-0
                                    "
                                >
                                    <span class="h1 icon2 text-primary">
                                        <i class="uil uil-calender"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Recommendation engine</h5>
                                        <p class="para text-muted mb-0">
                                            Smart partner recommendation engine that uses scoring and additional
                                            criteria to guide users to the optimal partner.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12 mt-4 pt-2">
                                <div
                                    class="
                                        card
                                        features
                                        fea-primary
                                        rounded
                                        p-4
                                        bg-light
                                        position-relative
                                        overflow-hidden
                                        border-0
                                    "
                                >
                                    <span class="h1 icon2 text-primary">
                                        <i class="uil uil-clock"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Automatic task routing</h5>
                                        <p class="para text-muted mb-0">
                                            Automatic distribution of tasks to team members, able to route tasks based
                                            on various criteria: region, competency limit, skill.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Features End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-platform',
    templateUrl: './platform.component.html',
    styleUrls: ['./platform.component.css'],
})

/***
 * Task Management Component
 */
export class PlatformComponent implements OnInit {
    /***
     * nav bg color add
     */
    navClass = 'nav-light';

    servicesData = [
        {
            icon: 'uil uil-edit-alt h1 text-primary',
            title: 'Smart',
            description:
                'Platform is performing complex tasks in a pleasant, concise, logical, and simple way for the user.',
        },
        {
            icon: 'uil uil-airplay h1 text-primary',
            title: 'Secure',
            description:
                'Data is encrypted both at rest and in transit. The platform is implemented using the latest security standards and protocols.',
        },
        {
            icon: 'uil uil-envelope-shield h1 text-primary',
            title: 'Composable',
            description:
                'Everything is declarative, reusable, and composable. From field groups, forms to process steps.',
        },
        {
            icon: 'uil uil-edit-alt h1 text-primary',
            title: 'Low code',
            description: 'Everything is configurable: Forms, Workflows, Worklist items, searchable fields, Follow-ups.',
        },
        {
            icon: 'uil uil-edit-alt h1 text-primary',
            title: 'Integrated',
            description:
                'Lots of out of the box integrations. Easy to implement new integration flows using BPlatform own integration DSL.',
        },
    ];

    constructor() {}

    ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core';

interface client {
    image: string;
}

@Component({
    selector: 'app-clients-logo',
    templateUrl: './clients-logo.component.html',
    styleUrls: ['./clients-logo.component.css'],
})
export class ClientsLogoComponent implements OnInit {
    /**
     * Clients Logo
     */
    clients_logo: client[] = [
        {
            image: 'assets/images/client/ro_allianz_logo_black.png',
        },
        {
            image: 'assets/images/client/ro_asirom_logo_black.png',
        },
        {
            image: 'assets/images/client/ro_claimexpert_logo_black.png',
        },
        {
            image: 'assets/images/client/ro_vigms_logo_black.png',
        },
        {
            image: 'assets/images/client/ro_omniasig_logo_black.png',
        },
        {
            image: 'assets/images/client/ro_marsh_logo_black.png',
        },
    ];

    constructor() {}

    ngOnInit(): void {}
}

<div class="back-to-home rounded d-none d-sm-block">
    <a routerLink="/index" class="btn btn-icon btn-primary">
        <i-feather name="home" class="icons"></i-feather>
    </a>
</div>

<!-- MAINTENANCE PAGE -->
<section
    class="bg-home d-flex align-items-center"
    data-jarallax='{"speed": 0.5}'
    style="background-image: url('assets/images/maintenance.jpg')"
>
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 text-center">
                <a href="javascript:void(0)" class="logo h5"
                    ><img src="assets/images/driftdata-logo-inverted.png" height="24" alt=""
                /></a>
                <div class="text-uppercase text-white title-dark mt-2 mb-4 maintenance">
                    This page is under construction.
                </div>
                <p class="text-white-50 para-desc mx-auto para-dark">
                    Come back later to check it or contact us at <span class="text-white">contact@driftdata.ro</span>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <a routerLink="/index" class="btn btn-primary mt-4"
                    ><i class="mdi mdi-backup-restore"></i> Go Back Home</a
                >
            </div>
        </div>
    </div>
    <!-- end container -->
</section>
<!--end section-->
<!-- MAINTENANCE PAGE -->

<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title">Website Terms</h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5>Introduction</h5>

                        <p>
                            These Website Standard Terms and Conditions written on this webpage shall manage your use of
                            our website, DriftData accessible at www.driftdata.ro.
                        </p>

                        <p>
                            These Terms will be applied fully and affect to your use of this Website. By using this
                            Website, you agreed to accept all terms and conditions written in here. You must not use
                            this Website if you disagree with any of these Website Standard Terms and Conditions.
                        </p>

                        <p>Minors or people below 18 years old are not allowed to use this Website.</p>

                        <h5>Intellectual Property Rights</h5>

                        <p>
                            Other than the content you own, under these Terms, Drift Data Systems SRL and/or its
                            licensors own all the intellectual property rights and materials contained in this Website.
                        </p>

                        <p>
                            You are granted limited license only for purposes of viewing the material contained on this
                            Website.
                        </p>

                        <h5>Restrictions</h5>

                        <p>You are specifically restricted from all of the following:</p>

                        <ul>
                            <li>publishing any Website material in any other media;</li>
                            <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
                            <li>publicly performing and/or showing any Website material;</li>
                            <li>using this Website in any way that is or may be damaging to this Website;</li>
                            <li>using this Website in any way that impacts user access to this Website;</li>
                            <li>
                                using this Website contrary to applicable laws and regulations, or in any way may cause
                                harm to the Website, or to any person or business entity;
                            </li>
                            <li>
                                engaging in any data mining, data harvesting, data extracting or any other similar
                                activity in relation to this Website;
                            </li>
                            <li>using this Website to engage in any advertising or marketing.</li>
                        </ul>

                        <h5>Your Content</h5>

                        <p>
                            In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video
                            text, images or other material you choose to display on this Website. By displaying Your
                            Content, you grant Drift Data Systems SRL a non-exclusive, worldwide irrevocable, sub
                            licensable license to use, reproduce, adapt, publish, translate and distribute it in any and
                            all media.
                        </p>

                        <p>
                            Your Content must be your own and must not be invading any third-party’s rights. Drift Data
                            Systems SRL reserves the right to remove any of Your Content from this Website at any time
                            without notice.
                        </p>

                        <h5>Your Privacy</h5>

                        <p>Please read Privacy Policy.</p>

                        <h5>No warranties</h5>

                        <p>
                            This Website is provided "as is," with all faults, and Drift Data Systems SRL express no
                            representations or warranties, of any kind related to this Website or the materials
                            contained on this Website. Also, nothing contained on this Website shall be interpreted as
                            advising you.
                        </p>

                        <h5>Limitation of liability</h5>

                        <p>
                            In no event shall Drift Data Systems SRL, nor any of its officers, directors and employees,
                            shall be held liable for anything arising out of or in any way connected with your use of
                            this Website whether such liability is under contract.  Drift Data Systems SRL, including
                            its officers, directors and employees shall not be held liable for any indirect,
                            consequential or special liability arising out of or in any way related to your use of this
                            Website.
                        </p>

                        <h5>Indemnification</h5>

                        <p>
                            You hereby indemnify to the fullest extent Drift Data Systems SRL from and against any
                            and/or all liabilities, costs, demands, causes of action, damages and expenses arising in
                            any way related to your breach of any of the provisions of these Terms.
                        </p>

                        <h5>Severability</h5>

                        <p>
                            If any provision of these Terms is found to be invalid under any applicable law, such
                            provisions shall be deleted without affecting the remaining provisions herein.
                        </p>

                        <h5>Variation of Terms</h5>

                        <p>
                            Drift Data Systems SRL is permitted to revise these Terms at any time as it sees fit, and by
                            using this Website you are expected to review these Terms on a regular basis.
                        </p>

                        <h5>Assignment</h5>

                        <p>
                            The Drift Data Systems SRL is allowed to assign, transfer, and subcontract its rights and/or
                            obligations under these Terms without any notification. However, you are not allowed to
                            assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                        </p>

                        <h5>Entire Agreement</h5>

                        <p>
                            These Terms constitute the entire agreement between Drift Data Systems SRL and you in
                            relation to your use of this Website, and supersede all prior agreements and understandings.
                        </p>

                        <h5>Governing Law & Jurisdiction</h5>

                        <p>
                            These Terms will be governed by and interpreted in accordance with the laws of Rpmania, and
                            you submit to the non-exclusive jurisdiction of the courts located in Romania for the
                            resolution of any disputes.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

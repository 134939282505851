<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/aboutus.jpg')">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="text-white title-dark">About us</h1>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row align-items-center" id="counter">
            <div class="col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-12">
                    <div class="d-flex mb-4">
                        <span class="text-primary h1 mb-0"
                            ><span
                                class="counter-value display-1 fw-bold"
                                [CountTo]="8"
                                [from]="0"
                                [duration]="4"
                            ></span
                            >+</span
                        >
                        <span class="h6 align-self-end ms-2"
                            >Years <br />
                            Experience</span
                        >
                    </div>
                    <div class="section-title">
                        <h4 class="title mb-4">Who we are ?</h4>
                        <p class="text-muted">
                            <strong>Drift Data Systems </strong>(DriftData) was founded in 2013 by two technology experts 
                            with a joint vision of automating business processes within organizations to create added 
                            value for the company. DriftData is specialized in custom software development, providing 
                            companies with fast, flexible, and integrated solutions for optimizing operational costs,
                            efficient use of generated data, and enhancing processes through automation.
                        </p>
                        <p class="text-muted">
                            The main projects and services provided by our company have contributed to the development
                             of solid knowledge and skills in areas such as:
                        </p>

                        <ul class="text-muted">
                            <li>Complete digitization of the claim notification and claim handling process;</li>
                            <li>
                                Automatic transfer of the claim files between different actors (internal departments,
                                third parties);
                            </li>
                            <li>Digital workflows in customer interaction;</li>
                            <li>
                                Collaborative document management among the various actors participating in the process;
                            </li>
                            <li>
                                Integration with damage evaluation systems, implementation of automatic flows, and
                                application of tariffs according to different contractual rules;
                            </li>
                            <li>Integration with core systems for data validation and data migration;</li>
                            <li>Automatic synchronization of data between heterogeneous systems;</li>
                            <li>
                                Implementation of recommendation engines based on system events and customer feedback;
                            </li>
                            <li>Web applications development;</li>
                            <li>Maintenance and technical support services;</li>
                            <li>Business analysis, consulting, and project management.</li>
                        </ul>
                        <p class="text-muted">
                            Our company's policy is based on the requirements of ISO 9001: 2015 and ISO 27001: 2018, 
                            and DriftData's certification for the two standards stated above demonstrates conformity 
                            to these standards.
                        </p>
                        <a [routerLink]="'/contact'" class="btn btn-primary mt-3">Contact us</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

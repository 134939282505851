<app-header
    [navClass]="addclass"
    [buttonList]="buttonShow"
    [sliderTopbar]="TopbarShow"
    [isdeveloper]="developerPage"
    [shopPages]="shopPages"
></app-header>

<router-outlet (activate)="onActivate($event)"></router-outlet>

<app-footer [footerVariant]="footerClass"></app-footer>

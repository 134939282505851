<section class="bg-half-120 bg-primary d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12">
                <div class="title-heading text-center">
                    <h1 class="heading title-dark text-white mb-3">Claims Bee</h1>
                    <p class="para-desc mx-auto text-white-50">Complete claims management solution</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Features Start -->
<section class="section mt-0">
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="/assets/images/bplatform/claimsbee_overview.png" class="img-fluid shadow rounded-md" alt="" />
            </div>

            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">Complete claims management solution</h4>
                    <p class="text-muted">
                        Claims management process that favors collaboration among parties (insurer, external
                        assessors, business partners) and can be integrated with core systems, documents management
                        systems, evaluation systems resulting in a modern end-to-end process for the digital age.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Main activities</h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <ul ngbNav #nav="ngbNav" class="nav-pills flex-column rounded col-md-5 pr-0 bg-white">
                <li ngbNavItem class="bg-light rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Notification</h5>
                            <p class="text-muted tab-para mb-0">
                                Multiple claim notification channels: public form, call center agent, external mobile
                                app.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="saas-feature-shape-right position-relative">
                            <img
                                src="/assets/images/bplatform/notification.png"
                                class="img-fluid mx-auto d-block"
                                alt=""
                            />
                        </div>
                    </ng-template>
                </li>

                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Documents management</h5>
                            <p class="text-muted tab-para mb-0">
                                Fully digital documents collection and management process.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="saas-feature-shape-right position-relative">
                            <img
                                src="/assets/images/bplatform/documents.png"
                                class="img-fluid mx-auto d-block"
                                alt=""
                            />
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Survey (supplementary survey)</h5>
                            <p class="text-muted tab-para mb-0">
                                Complete survey support for both field and desk assessors. Various estimation strategies
                                (expert system - Audatex, internal). Optimized on tablets.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <img src="/assets/images/bplatform/survey.png" class="img-fluid mx-auto d-block" alt="" />
                    </ng-template>
                </li>
                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Coverage checks</h5>
                            <p class="text-muted tab-para mb-0">
                                Semaphore with checks to help the adjuster to keep track of his checklist.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <img
                            src="/assets/images/bplatform/coverage_checks.png"
                            class="img-fluid mx-auto d-block"
                            alt=""
                        />
                    </ng-template>
                </li>

                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Compensation requests</h5>
                            <p class="text-muted tab-para mb-0">
                                Compensation requests can be filled on various channels.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <img src="/assets/images/bplatform/compensation.png" class="img-fluid mx-auto d-block" alt="" />
                    </ng-template>
                </li>
                <li ngbNavItem class="bg-light mt-4 rounded-md">
                    <a ngbNavLink class="rounded">
                        <div class="p-3 text-left">
                            <h5 class="title font-weight-bold">Payments with approvals</h5>
                            <p class="text-muted tab-para mb-0">
                                Payments approvals with support for competency limit.
                            </p>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <img src="/assets/images/bplatform/payments.png" class="img-fluid mx-auto d-block" alt="" />
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="col-md-7 col-12 mt-4 pt-2"></div>
        </div>
        <!--end col-->
    </div>

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-12">
                <div class="sticky-bar">
                    <div class="section-title text-lg-left mb-4 mb-lg-0 pb-2 pb-lg-0">
                        <h4 class="title mb-4">And also</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-8 col-12">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div
                                    class="
                                        card
                                        features
                                        fea-primary
                                        rounded
                                        p-4
                                        bg-light
                                        position-relative
                                        overflow-hidden
                                        border-0
                                    "
                                >
                                    <span class="h1 icon2 text-primary">
                                        <i class="uil uil-meeting-board"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Documents collection</h5>
                                        <p class="para text-muted mb-0">
                                            System can guide the customer through the document image capture process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12 mt-4 pt-2">
                                <div
                                    class="
                                        card
                                        features
                                        fea-primary
                                        rounded
                                        p-4
                                        bg-light
                                        position-relative
                                        overflow-hidden
                                        border-0
                                    "
                                >
                                    <span class="h1 icon2 text-primary">
                                        <i class="uil uil-schedule"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Partner enrollment</h5>
                                        <p class="para text-muted mb-0">
                                            Partners can be enrolled in the platform and collaborate actively in the process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div
                                    class="
                                        card
                                        features
                                        fea-primary
                                        rounded
                                        p-4
                                        bg-light
                                        position-relative
                                        overflow-hidden
                                        border-0
                                    "
                                >
                                    <span class="h1 icon2 text-primary">
                                        <i class="uil uil-calender"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Claim Reserve</h5>
                                        <p class="para text-muted mb-0">
                                            Claim reserve is automatically updated after survey, redemption offer
                                            acceptance, payments.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Features End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
